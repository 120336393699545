<template>
  <div class="outboard-info">
    <!-- Brand -->
    <div class="outboard-info-row" v-if="product.manufacturer">
      <strong class="outboard-info-label">Brand</strong>
      <span class="outboard-info-data">{{ product.manufacturer }}</span>
    </div>
    <!-- Horsepower -->
    <div class="outboard-info-row" v-if="product.horsepower">
      <strong class="outboard-info-label">HP</strong>
      <span class="outboard-info-data">{{ product.horsepower[0] }} HP</span>
    </div>
    <!-- Starting System -->
    <div class="outboard-info-row" v-if="product.starting_system">
      <strong class="outboard-info-label">Starting System</strong>
      <span class="outboard-info-data">{{ product.starting_system[0] }}</span>
    </div>
    <!-- Shaft Length -->
    <div class="outboard-info-row" v-if="product.shaft">
      <strong class="outboard-info-label">Shaft Length</strong>
      <span class="outboard-info-data">{{ product.shaft[0] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "outboard-info",
  props: ["product"]
};
</script>