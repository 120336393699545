export default {
  methods: {
    capWords(str) {
      str = str.split(" ");

      for (let i = 0; i < str.length; i++) {
        if (str[i]) str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }

      return str.join(" ");
    },
    toSnakeCase(str) {
      return str.toLowerCase().replace(/ /g, "_");
    },
    toProperCase(str) {
      return this.capWords(str.replace(/\_/g, " "));
    },
  },
};
