export default {
  methods: {
    hasCookie(cookie) {
      if (
        document.cookie
          .split(";")
          .filter(item => item.trim().startsWith(cookie + "=")).length
      ) {
        return true;
      }

      return false;
    }
  }
};
