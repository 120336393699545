<script>
/**
 * Pass a type & props to create the application link data script tag.
 * 
 * @prop type - String
 * @prop productName
 * @prop productImages
 * @prop productImage
 * @prop productDescription
 * @prop productSku
 * @prop productMpn
 * @prop productBrand
 * @prop productReview
 * @prop productAggregateRating
 * @prop productOffers
 * @prop productLowPrice
 * @prop productMsrp
 * @prop productUrl
 * @prop productAvailable - Boolean
 * @return script
 */
// https://developers.google.com/search/docs/advanced/structured-data/product#shopping-aggregator-page

export default {
    name: "LdJsonCreator",
    props: {
        productName: { type: String, default: null },
        productImages: { type: Array, default: null },
        productImage: { type: String, default: null },
        productDescription: { type: String, default: null },
        productSku: { type: String, default: null },
        productMpn: { type: String, default: null },
        productBrand: { type: String, default: null },
        productReview: { type: Object, default: null },
        productAggregateRating: { type: Object, default: null },
        productOffers: { type: Object, default: null },
        productLowPrice: { type: Number, default: null },
        productMsrp: { type: Number, default: null },
        productUrl: { type: String, default: null },
        productAvailable: { type: Boolean, default: true },
        type: { type: String, default: null },
    },
    computed: {
        /**
         * Return the application/ld+json based upon the 
         * passed prop: type.
         * 
         * @return JSON
         */
        ldJson() {
            /**
              * Expand the switch statement by creating a new case for
              * the possible passed type.
             */
            switch(this.type) {
                case 'aggregateProductOffer':
                    return `{
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${this.productName}",
                        "image": [
                            "${this.productImage}"
                        ],
                        "description": "${this.productDescription}",
                        "sku": "${this.productSku}",
                        "mpn": "${this.productMpn}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${this.productBrand}"
                        },
                        "offers": {
                            "@type": "AggregateOffer",
                            "offerCount": "1",
                            "lowPrice": "${this.productLowPrice}",
                            "highPrice": "${this.productMsrp}",
                            "priceCurrency": "USD"
                        }
                    }`;
                case 'Product':
                    return `{
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${this.productName}",
                        "image": [
                            "${this.productImage}"
                        ],
                        "description": "${this.productDescription}",
                        "sku": "${this.productSku}",
                        "mpn": "${this.productMpn}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${this.productBrand}"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "${this.productUrl}",
                            "priceCurrency": "USD",
                            "price": "${this.productLowPrice}",
                            "priceValidUntil": "2020-11-20",
                            "itemCondition": "https://schema.org/NewCondition",
                            "availability": "https://schema.org/${this.productAvailable ? 'InStock' : 'OutOfStock'}"
                        }
                    }`;
                default:
                    return;
            }
        }
    },
}
</script>

<template>
    <component :is="'script'" type="application/ld+json" v-html="ldJson"></component>
</template>