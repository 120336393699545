<template>
  <div class="product-card">
    <div>
      <a
        :href="fullPartUrl"
        :title="productName"
        @click.prevent="trackProductClick"
        :alt="productName"
      >
        <div
          class="product-image"
          :style="{
            backgroundImage: `url(${decideImageURL(
              siteCdn,
              productImage,
              'card'
            )})`,
          }"
        ></div>
      </a>

      <div class="product-name-container">
        <a
          :href="fullPartUrl"
          class="product-name"
          @click.prevent="trackProductClick"
          >{{ isOutboard ? partNumber : capWords(productName) }}</a
        >

        <div v-if="!isOutboard" style="line-height: 1">
          <small v-if="!productDecoded.is_collection">
            <strong>{{ partNumber }}</strong>
            <br />
          </small>
          <small>{{ vendor }}</small>
        </div>
      </div>
    </div>

    <div
      class="product-card-body"
      :class="{ 'collection-body': hasField('collection_hash') }"
    >
      <div v-if="productFits" class="part-fits">
        <i class="fa fa-fw fa-check text-success"></i> Fits your
        {{ vehicleType }}!
      </div>

      <!-- OUTBOARD MOTOR INFO -->
      <outboard-info v-if="isOutboard" :product="productDecoded" />

      <inventory-info
        :csrf="csrf"
        :id="productId"
        :cda="parseInt(cdaLevel)"
        :site="site"
        :doc-key="docKey"
        :product="productDecoded"
        :auth="auth"
        :outboard="isOutboard"
        :event-category="eventCategory"
        :pixel="pixel"
        :inventory="productDecoded"
        :stock-day-min="stockDayMin"
        :stock-day-max="stockDayMax"
        :holiday="holiday"
        :add-min="addMin"
        :add-max="addMax"
        :ride="storedRide"
        :dc="dc"
        :replacements="replacements"
        :query="query"
        :user-email="userEmail"
        :user-data="userData"
        :query-pipeline="queryPipeline"
        :query-bearer-token="queryBearerToken"
        :signal-query-pipeline="signalQueryPipeline"
        :fusion-query-id="fusionQueryId"
        :app-id="appId"
        :host="host"
		:captcha-site-key="captchaSiteKey"
      />
    </div>

    <ld-json-creator
      type="aggregateProductOffer"
      :product-name="productName"
      :product-brand="productDecoded.manufacturer"
      :product-description="null"
      :product-image="decideImageURL(siteCdn, productImage, 'card')"
      :product-sku="productDecoded.sku"
      :product-mpn="partNumber"
      :product-review="null"
      :product-aggregate-rating="null"
      :product-offers="null"
      :product-msrp="productDecoded.price_msrp"
      :product-low-price="productDecoded.price"
      :product-url="fullPartUrl"
    />
  </div>
</template>

<script>
import Strings from "../../mixins/strings";
import Images from "../../mixins/images";
import Garage from "../../mixins/garage";
import Product from "../../mixins/product";
import OutboardInfo from "./OutboardInfo.vue";
import InventoryInfo from "./InventoryInfo.vue";
import LdJsonCreator from "../widgets/LdJsonCreator.vue";

import axios from "axios";

export default {
  name: "product-card",
  mixins: [Strings, Images, Garage, Product],
  props: {
    product: { type: [String, Object, Array] },
    csrf: { type: String },
    site: { type: String, default: "pz" },
    cdaLevel: { type: [String, Number], default: 0 },
    auth: { type: [String, Number], default: 0 },
    eventCategory: { type: String, default: "Product Card" },
    breadcrumbRef: { type: String, default: null },
    pixel: { type: String, default: null },
    stockDayMin: { default: "1" },
    stockDayMax: { default: "2" },
    addMin: { default: "0" },
    addMax: { default: "0" },
    holiday: { type: Boolean, default: false },
    dc: { type: String },
    replacements: { type: Boolean },
    query: { type: String, default: null },
    queryPipeline: { type: String },
    queryBearerToken: { type: String },
    userData: { type: String },
    userEmail: { type: String },
    signalQueryPipeline: { type: String },
    productPosition: { type: [String, Number], default: null },
    fusionQueryId: { type: String },
    fusionUserId: { type: String },
    host: { type: String },
    appId: { type: [String, Number] },
	captchaSiteKey: { type: String }
  },
  components: { OutboardInfo, InventoryInfo, LdJsonCreator },

  computed: {
    productDecoded() {
      let json;

      try {
        let decoded = atob(this.product);
        json = JSON.parse(decoded);
      } catch (e) {
        json = this.product;
      }

      return json;
    },
    productId() {
      if (this.hasField("store_product_id")) {
        return this.productDecoded.store_product_id;
      } else if (this.hasField("product_id")) {
        return this.productDecoded.product_id;
      } else if (this.hasField("price_file")) {
        return this.productDecoded.price_file.id;
      } else {
        return null;
      }
    },
    productName() {
      if (
        this.hasField("collection_name") &&
        this.productDecoded.is_collection
      ) {
        return this.productDecoded.collection_name;
      } else if (this.hasField("name")) {
        return this.productDecoded.name;
      } else {
        return this.productDecoded.product_name;
      }
    },
    eventName() {
      if (this.hasField("is_collection")) {
        if (this.productDecoded.is_collection)
          return "Collection: " + this.productName;
      }

      return this.productName;
    },
    productImage() {
      if (this.hasField("entity")) {
        return this.productDecoded.entity.image[0];
      } else if (this.hasField("product_image")) {
        if (Array.isArray(this.productDecoded.product_image)) {
          return this.productDecoded.product_image[0];
        }
        if (typeof this.productDecoded.product_image === "string") {
          return this.productDecoded.product_image;
        }
      }
    },
    partNumber() {
      if (this.productDecoded.is_collection) {
        return "";
      }

      if (this.hasField("product_sku")) {
        return this.productDecoded.product_sku;
      }

      if (this.hasField("sku")) {
        return this.productDecoded.sku;
      }

      if (
        this.hasField("entity") &&
        this.productDecoded.entity.manufacturer_part_number
      ) {
        return this.productDecoded.entity.manufacturer_part_number[0];
      } else {
        return this.productDecoded.manufacturer_part_number;
      }
    },
    vendor() {
      let vendor = "";

      if (this.hasField("vendor_name")) {
        return this.productDecoded.vendor_name;
      }

      if (this.hasField("vendor"))
        if (
          this.productDecoded.vendor &&
          Object.keys(this.productDecoded.vendor).includes("name")
        ) {
          vendor = this.productDecoded.vendor.name;
        }

      if (this.hasField("entity") && this.productDecoded.entity.manufacturer) {
        vendor = this.productDecoded.entity.manufacturer[0];
      } else if (
        this.hasField("manufacturer") &&
        this.productDecoded.manufacturer != null
      ) {
        vendor = this.productDecoded.manufacturer;
      } else {
        vendor = this.productDecoded.vendor;
      }

      return this.capWords(vendor);
    },
    fullPartUrl() {
      if (this.breadcrumbRef) {
        let key = "/" + this.docKey;
        let delim = this.docKey.includes("?") ? "&" : "?";
        let ref = "ref=" + this.breadcrumbRef;

        return key + delim + ref;
      } else {
        return "/" + this.docKey;
      }
    },
    docKey() {
      if (this.hasField("doc_key")) {
        return this.productDecoded.doc_key;
      } else {
        return this.productDecoded.product_doc_key;
      }
    },
    isOutboard() {
      if (this.hasField("price_file")) {
        return this.productDecoded.price_file.type_id === "3";
      } else {
        return this.productDecoded.type_id === "3";
      }

      if (this.hasField("entity")) {
        return this.productDecoded.entity.product_type[0] === "3";
      }
    },
    vehicleType() {
      if (this.site === "pz") return "ride";
      if (this.site === "bn") return "engine";
    },
    siteCdn() {
      // const cf = "cdn-cgi/image/width=270,quality=80/";

      switch (this.site) {
        case "pz":
          return "cdn.partzilla.com";
        case "bn":
          return "cdn.boats.net";
        case "fd":
          return "cdn.firedog.com";
      }
    },
    userDataDecoded() {
      return atob(this.userData);
    },
    findSiteName() {
      if (this.site === "pz") return "Partzilla";
      if (this.site === "bn") return "Boats.net";
      if (this.site === "fd") return "Firedog";
    },
    queryForFusion() {
      if (
        this.query !== undefined &&
        this.query !== null &&
        this.query !== "" &&
        !this.query
      ) {
        return this.query;
      }

      return this.productName;
    },
  },

  methods: {
    hasField(field) {
      return Object.keys(this.productDecoded).includes(field);
    },
    trackProductClick() {
      this.gaEcommerceSelectItem();

      // Fusion
      if (this.pixel) {
        axios.post("/search/pixel", { sobj: this.pixel, signal_type: "click" });
      }

      this.sendEventToFusion("click");
    },
    gaEcommerceSelectItem() {
      window.dataLayer.push({ ecommerce: null });

      let item = {};

      if (this.productDecoded.is_collection) {
        item = {
          item_name: "Collection - " + this.productDecoded.collection_name,
        };
      } else {
        item = {
          item_name: this.productName,
          item_id: this.productDecoded.sku,
          price: parseFloat(this.productDecoded.price),
        };
      }

      window.dataLayer.push({
        event: this.cda === 1 ? "select_item_cda" : "select_item",
        ecommerce: {
          items: [item],
        },
      });
    },
  },

  beforeMount() {
    this.checkForStoredRide();

    if (this.hasStoredRide) {
      this.getStoredRide();
    }
  },
};
</script>

<style lang="scss">
.product-cards {
  display: grid;
  grid-template-rows: auto;
  gap: 1rem;
  margin: 2rem 0;
  .product-card-container {
    display: flex;
    .product-card {
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      width: 100%;

      .product-image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 22%;
        min-height: 200px;
        margin-bottom: 0.75rem;
      }

      .product-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        line-height: 1.2;
        padding: 0;

        div.part-fits {
          margin-top: 3px;
          padding: 3px;
          border-radius: 3px;
          background-color: #f4f4f4;
          color: #666;
        }

        &.collection-body {
          justify-content: flex-start;
        }

        small {
          color: #777;
        }

        .outboard-info {
          .outboard-info-row {
            display: flex;

            .outboard-info-label,
            .outboard-info-data {
              flex-basis: 50%;
            }
          }
        }

        .buttons {
          margin-bottom: 12px;
        }

        .product-card-divider {
          margin: 12px 0;
        }

        a.product-name {
          font-weight: 700;
          display: block;
          width: 200px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 500px) {
  .product-cards {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .product-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (min-width: 768px) {
  .product-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (min-width: 990px) {
  .product-cards.cards-feed {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 500px) {
  .product-cards {
    .product-card-container {
      .product-card {
        .product-image {
          min-height: 120px;
        }
      }
    }
  }
}
</style>
