<template>
  <div>
    <div v-if="inventory && available">
      <div v-if="stock >= 1" class="productStock">
        <span class="text-success in-stock">
          <i class="fa fa-fw fa-check-circle-o"></i>&nbsp;In Stock
        </span>
        &nbsp;•&nbsp;
        <a
          href="javascript:void(0)"
          data-toggle="popover"
          data-trigger="focus"
          data-content="Ships today if ordered before 3:00PM EST Monday - Friday"
          class="stock-link"
          v-if="locationCheck && !holiday"
          >Ships within 24 hrs.</a
        >
        <a
          href="javascript:void(0)"
          data-toggle="popover"
          data-trigger="focus"
          :data-content="'Expected to ship by ' + availabilityDates"
          class="stock-link"
          v-if="locationCheck && holiday"
        >
          <i class="fa fa-fw fa-clock-o"></i>
          &nbsp; Ships in {{ minDays }} to {{ maxDays }} days</a
        >
        <a
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#shipping-address-modal"
          v-else-if="!locationCheck"
        >
          <i class="fa fa-map-marker fa-fw"></i>&nbsp; Check Availability
        </a>
      </div>

      <div v-if="stock === 0" class="productStock">
        <a
          href="javascript:void(0)"
          data-toggle="popover"
          data-trigger="focus"
          :data-content="'Expected in stock ' + availabilityDates"
          class="stock-link"
          v-if="locationCheck"
        >
          <i class="fa fa-fw fa-clock-o"></i>
          &nbsp; Ships in {{ minDays }} to {{ maxDays }} days
        </a>

        <a
          href="javascript:void(0)"
          data-toggle="popover"
          data-trigger="focus"
          :data-content="'Stock and price vary by selected product.'"
          class="stock-link text-success"
          v-else-if="isCollection && !singlePrice && !isInfo"
        >
          <i class="fa fa-info-circle fa-fw"></i>
          <strong>View Details</strong> for availability
        </a>

        <a
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#shipping-address-modal"
          v-else
        >
          <i class="fa fa-map-marker fa-fw"></i>&nbsp; Check availability
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Cookies from "../../mixins/cookies.js";
import Product from "../../mixins/product.js";

export default {
  name: "stock",
  props: {
    inventory: { type: Object },
    stockDayMin: { type: [String, Number], default: "1" },
    stockDayMax: { type: [String, Number], default: "2" },
    addMin: { type: String },
    addMax: { type: String },
    holiday: { type: Boolean },
    singlePrice: { type: Boolean, default: false },
    isInfo: { type: Boolean },
    dc: { type: String },
  },
  mixins: [Cookies, Product],

  computed: {
    availabilityDates() {
      let min = this.addBusinessDays(moment(), this.minDays);
      let max = this.addBusinessDays(moment(), this.maxDays);

      return min.format("MMM D") + " - " + max.format("D");
    },
    minDays() {
      if (this.holiday && this.stock === 0) {
        return (parseInt(this.stockDayMin) + parseInt(this.addMin)).toString();
      }

      if (this.holiday) return this.addMin;

      return this.stockDayMin ? this.stockDayMin : "1";
    },
    maxDays() {
      if (this.holiday && this.stock === 0) {
        return (parseInt(this.stockDayMax) + parseInt(this.addMax)).toString();
      }

      if (this.holiday) return this.addMax;

      return this.stockDayMax ? this.stockDayMax : "2";
    },
    locationCheck() {
      return true;
      //return (
       // this.hasCookie("user_avail_loc") && this.hasCookie("cart_ship_loc")
      //);
    },
    stock() {
      if (parseInt(this.dc) === 1) {
        return parseInt(this.inventory.dc1_stock);
      } else if (parseInt(this.dc) === 2) {
        return parseInt(this.inventory.dc2_stock);
      } else {
        // user global_stock/stock_global
        if (Object.keys(this.inventory).includes("stock_global")) {
          if (this.inventory.stock_global === null) return 0;

          return parseInt(this.inventory.stock_global);
        }

        if (Object.keys(this.inventory).includes("global_stock")) {
          if (this.inventory.global_stock === null) return 0;

          return parseInt(this.inventory.global_stock);
        }
      }

      return 0;
    },
  },

  methods: {
    addBusinessDays(originalDate, numDaysToAdd) {
      const Sunday = 0;
      const Saturday = 6;
      let daysRemaining = numDaysToAdd;

      const newDate = originalDate.clone();

      while (daysRemaining > 0) {
        newDate.add(1, "days");
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
          daysRemaining--;
        }
      }

      return newDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.productStock {
  .in-stock {
    font-size: 1em;
  }
}
</style>
