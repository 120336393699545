export default {
  methods: {
    money(val) {
      try {
        const float = parseFloat(val).toFixed(2);
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(float).replace('$', '');
      } catch (err) {
        console.error(val + " is not a valid number.");
      }
    },
  },
};
