<template>
	<div>
		<!-- outboard -->
		<template v-if="compareMulti(parsedMapRule, [7, 8, 9])">
			<div class="outboard-info">
				<template v-if="parsedMapRule == 7">
					<div class="outboard-info-row">
						<strong class="outboard-info-label">MSRP</strong>
						<span class="outboard-info-data strikethrough slashed-price"> ${{ priceMsrp }} </span>
					</div>
					<div class="outboard-info-row">
						<strong class="outboard-info-label">Price</strong>
						<span
							class="outboard-info-data sale-price"
							itemprop="price"
							:content="price"
						>
							${{ price }}
						</span>
					</div>
				</template>
				<template v-else>
					<div class="outboard-info-row">
						<strong class="outboard-info-label">MSRP</strong>
						<span class="outboard-info-data strikethrough slashed-price"> ${{ priceMsrp }} </span>
					</div>
				</template>
			</div>
			<a
				v-if="!isOutboard"
				:href="docKey"
				:style="styleOptions"
				itemprop="price"
				:content="price"
			>
				${{ price }}
			</a>
		</template>
		<template v-else>
			<span :class="{'strikethrough slashed-price text-muted': canShowPercent}">
				<template v-if="showSkeleton || !canShowPercent">
					&nbsp;
				</template>
				<template v-else>
					${{ priceMsrp }}
				</template>
			</span>
			&nbsp;
			<span :class="{ 'text-success': canShowPercent }">
				<template v-if="showSkeleton || !canShowPercent">
					&nbsp;
				</template>
				<template v-else>
					{{ percentSavings }}% OFF
				</template>
			</span>
			<br />
			<span
				class="sell-price"
				itemprop="price"
				:content="price"
			>
				${{ price }}
			</span>
		</template>
	</div>
</template>

<script>
import Product from '@/mixins/product.js';

export default {
	name: 'pricing-display',
	mixins: [Product],
	props: {
		showSkeleton: {
			type: Boolean,
			default: false,
		},
		/**
		 * Money mixin casts as string
		 */
		price: {
			type: String,
			required: true,
		},
		/**
		 * Money mixin casts as string
		 */
		priceMsrp: {
			type: String,
			required: true,
		},
		canShowPercent: {
			type: Boolean,
			default: true,
		},
		percentSavings: {
			type: Number,
		},
	},
}
</script>