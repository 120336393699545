export default {
    methods: {
        decideImageURL(cdn, imageURL = String, size = String) {
            let imageBank;
            let cdnParameters;
            // deal with sizing
            switch(size) {
                case "thumbnail":
                    cdnParameters = 'width=40';
                    break;
                case "full":
                    cdnParameters = 'width=768,quality=75';
                    break;
                case "card":
                    cdnParameters = 'width=270,quality=80';
                    break;
                default:
                    cdnParameters = 'width=768,quality=75';
                    break;
            }

            // actual image urls
            if(imageURL.includes('.cloudfront.net/')) {
                imageBank = `${imageURL} + ?${cdnParameters}`;
            } else {
                imageBank = `https://${cdn}/cdn-cgi/image/${cdnParameters}/${imageURL}`;
            }

            return imageBank;
        }
    }
}