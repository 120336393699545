import axios from 'axios';
import sha1 from 'js-sha1';
export default {
  computed: {
	productData() {
		if (this.inventory !== undefined) {
			if (
				typeof this.inventory === "string" ||
				this.inventory instanceof String
			) {
				return this.inventoryDecoded;
			} else {
				return this.inventory;
			}
		}

		if (this.product !== undefined) {
			if (
				typeof this.product === "string" ||
				this.product instanceof String
			) {
				return this.productDecoded;
			} else {
				return this.product;
			}
		}
	},
    parsedStatus() {
		if (this.productData.is_sellable) return 1;
		if (this.productData.status) return parseInt(this.productData.status);
		if (this.priceFile) {
			if (this.priceFile.status && this.priceFile.status !== null) return parseInt(this.priceFile.status);
			if (this.priceFile.is_sellable) return 1;
			return 0;
		}
		return 0;
    },
    available() {
		if (this.productData) {
			// if (this.isPropellerSearch) return true;
			if (this.parsedStatus === 0) return false;
			if (this.parsedStatus === 1) return true;
			// if (this.isCollection) return true;

			return (
				!this.compareMulti(this.parsedMapRule, [7, 9]) &&
				this.parsedStatus !== 0
			);
		}
    },
    parsedMapRule() {
		if (this.priceFile) {
			if (this.priceFile.map_id && this.priceFile.map_id !== null) return parseInt(this.priceFile.map_id);
		}
		if (this.productData) {
			if (this.productData.price_file) {
				return parseInt(this.productData.price_file.map_id);
			}
        	return parseInt(this.productData.map_id);
		}
		return null;
    },
    aftermarketMap() {
		if (!Object.values(this.productData).includes("map_id")) {
			return false;
		}

		return (
			this.productData.map_id === null ||
			this.compareMulti(this.parsedMapRule, [1, 2, 3])
		);
    },
    isCollection() {
		if (this.productData.price_file) {
			return this.productData.price_file.is_collection;
		}
		return this.productData.is_collection;
    },
	productFits() {
		if (this.garageFitmentId && this.productData.fitment) {
			return this.productData.fitment.includes(this.garageFitmentId.toString());
		}
		return false;
	}
  },
  methods: {
    compareMulti(rule, test) {
      return test.includes(rule);
    },
	sendEventToFusion(type) {
		function getRandomUUID() {
			return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (cryptoValue) =>
				(cryptoValue ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> cryptoValue / 4).toString(16)
			);
		}

		const createUUID = self.crypto.randomUUID ? self.crypto.randomUUID() : getRandomUUID();
		const sessionCookie = document.cookie.split('; ').find(row => row.startsWith('PHPSESSID=')).split('=')[1];
		//	Fusion training data
		axios.post(
			`${atob(this.signalQueryPipeline)}/signals`,
			[{
				"id": createUUID, // signals uuid here
				"timestamp": new Date().getTime(),
				"type": type, // type of signal
				"params":{
					"fusion_query_id": `${this.fusionQueryId}`, // looking for x-fusion-query-id
					"user_id": `${this.userEmail}`, // unique id for the user that generated the signal
					"session": `${sessionCookie}`, // unique id for the user's browser session, from browsers local storage
					"query": `${this.queryForFusion ? this.queryForFusion : this.query}`, // the query that was used to display the products
					"ctype": "result", // click type, example: result
					"filter": [
						"type/Product"
					],
					"ip_address": `${this.userDataDecoded ? this.userDataDecoded : atob(this.userData)}`, // users ip address
					"host": `${this.hostDecoded ? this.hostDecoded : atob(this.host)}`, // host name that is hosting the app that is generating this signal
					"doc_id": `${this.productData.id}`, // product doc id
					"app_id": `${this.appId}`, // the site name
					"res_pos": `${this.productPosition ? this.productPosition : 0}`, // Position of the clicked result within the list of results
					"filter_field": [
						"type"
					]
				}
			}], {
				headers: {
					'Authorization': 'Bearer ' + atob(this.queryBearerToken),
				}
			}
		)
		.catch((error) => {
			console.log('There was an issue sending the data: ', error);
		})
		.finally(() => {
			if (type === 'click') {
				let docKey = this.docKey ? '/' + this.docKey : this.fullPartUrl ?? '/' + this.getDocKey;
				if (this.ref !== null && this.ref !== undefined) {;
					docKey += `?ref=${this.ref}`;
				}
				if (this.refKey !== null && this.refKey !== undefined) {
					docKey += `?ref=${this.refKey}`;
				}
				if (this.breadcrumbRef !== null && this.breadcrumbRef !== undefined) {
					docKey += `?ref=${this.breadcrumbRef}`;
				}
				window.location = docKey;
			}
		});
	},
    reurl() {
      	return btoa(window.location.href);
    },
  },
};
