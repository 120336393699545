<template>
	<div
		class="inventory-info"
		:class="{ 'collection-info': isCollection }"
	>
		<div
			class="info"
			:class="{ collection: isCollection }"
		>
			<div
				class="text-center"
				v-if="!inventory && !inventoryFail"
				style="padding: 5rem 0"
			>
				<i class="fa fa-spinner fa-spin fa-lg fa-fw"></i>
				<small>Calculating shipping info...</small>
			</div>
			<div
				class="text-center"
				v-if="!inventory && inventoryFail"
				style="padding: 1rem 0"
			>
				<a
					:href="docKey"
					@click.prevent="trackProductClick"
					class="btn btn-link btn-block"
				>
					View Product
				</a>
			</div>
			<div
				v-else-if="!available && inventory && !inventoryFail"
				style="padding: 1rem 0"
			>
				<span class="text-muted">Currently Unavailable</span>
			</div>
			<div v-else>
				<pricing
					:inventory="priceFile"
					:cda="cda"
					:auth="parseInt(auth)"
					:type="parseInt(productType)"
					:doc-key="docKey"
				/>
				<stock
					v-if="!outboard"
					:inventory="priceFile"
					:stock-day-min="stockDayMin"
					:stock-day-max="stockDayMax"
					:holiday="holiday"
					:add-min="addMin"
					:add-max="addMax"
					:dc="dc"
				/>
				<span
					v-if="outboard"
					class="text-success in-stock"
				>
					<br />
					<i class="fa fa-fw fa-check-circle-o"></i>&nbsp;In Stock
				</span>
			</div>
		</div>

		<div class="buttons">
			<div
				class="product-card-divider"
				v-if="inventory"
			></div>

			<add-to-cart
				:id="id"
				:site="site"
				:doc-key="docKey"
				:inventory="priceFile"
				:sku="product.sku"
				:name="productName"
				:cda="cda"
				:type="parseInt(productType)"
				:auth="parseInt(auth)"
				:event-category="eventCategory"
				:pixel="pixel"
				:ride="ride"
				:captcha-site-key="captchaSiteKey"
				@sendEventToFusion="sendEventToFusion"
			/>
			<!-- <div class="product-card-divider" v-if="replacements"></div> -->

			<!-- <a
        :href="replacements"
        class="btn btn-default-outline"
        :class="{ 'btn-block': block, 'btn-sm': small }"
        v-if="replacements"
        >View Alternatives</a
      > -->
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Promise from 'promise-polyfill';
import Product from '../../mixins/product.js';
import Garage from '../../mixins/garage.js';
import Pricing from './Pricing.vue';
import Stock from './Stock.vue';
import AddToCart from './AddToCart.vue';

export default {
	name: 'inventory-info',

	mixins: [Product, Garage],
	components: { Pricing, Stock, AddToCart },

	props: {
		csrf: { type: String, default: 'sdfjkhsdfhksdf7sdfbsdf6' },
		id: { type: [String, Number] },
		cda: { type: [String, Number] },
		auth: { type: [String, Number] },
		site: { type: String },
		docKey: { type: String },
		product: { type: Object, default: null },
		outboard: { type: Boolean, default: false },
		eventCategory: { type: String },
		pixel: { type: String },
		inventory: { type: Object, default: null },
		stockDayMin: { default: '' },
		stockDayMax: { default: '' },
		addMin: { default: '' },
		addMax: { default: '' },
		holiday: { default: false },
		ride: { type: Object },
		replacements: { default: false },
		dc: { type: String },
		query: { type: String, default: null },
		queryPipeline: { type: String },
		queryBearerToken: { type: String },
		userData: { type: String },
		userEmail: { type: String },
		signalQueryPipeline: { type: String },
		productPosition: { type: [String, Number], default: null },
		fusionQueryId: { type: String },
		fusionUserId: { type: String },
		host: { type: String },
		appId: { type: [String, Number] },
		captchaSiteKey: { type: String, default: null }
	},

	data() {
		return {
			inventoryFail: false,
		};
	},

	computed: {
		uri() {
			let endpoint = '/product/inventory/' + this.id + '?api=1&csrf=' + this.csrf + '';

			if (window.location.hostname.includes('localhost')) {
				if (this.site === 'pz') return 'https://www.partzilla.com' + endpoint;
				if (this.site === 'bn') return 'https://www.boats.net' + endpoint;
			}

			return endpoint;
		},
		productType() {
			if (Object.keys(this.product).includes('entity')) {
				return this.product.entity.product_type[0];
			} else if (Object.keys(this.product).includes('price_file')) {
				return this.product.price_file.type_id;
			} else {
				return this.product.type_id;
			}
		},
		priceFile() {
			if (Object.keys(this.inventory).includes('price_file')) {
				return this.inventory.price_file;
			} else {
				return this.inventory;
			}
		},
		productName() {
			if (Object.keys(this.inventory).includes('name')) {
				return this.inventory.name;
			} else {
				return this.inventory.product_name;
			}
		},
	},

	methods: {
		trackProductClick() {
			this.gaEcommerceSelectItem();
			this.sendEventToFusion('click');
		},
		gaEcommerceSelectItem() {
			window.dataLayer.push({ ecommerce: null });

			let item = {};

			if (this.product.is_collection) {
				item = {
					item_name: 'Collection - ' + this.product.collection_name,
				};
			} else {
				item = {
					item_name: this.productName,
					item_id: this.product.sku,
					price: parseFloat(this.product.price),
				};
			}

			window.dataLayer.push({
				event: this.cda === 1 ? 'select_item_cda' : 'select_item',
				ecommerce: {
					items: [item],
				},
			});
		},
	},

	mounted() {
		if (!this.inventory) {
			axios
				.get(this.uri)
				.then((result) => {
					this.inventory = result.data;
					setTimeout(() => {
						$('[data-toggle="popover"]').popover();
					}, 1000);
				})
				.catch((error) => {
					this.inventoryFail = true;
				});
		}
	},
};
</script>

<style lang="scss">
.inventory-info {
	display: flex;
	flex-direction: column;
	margin-top: 12px;
	// flex: 1;
	justify-content: space-between;

	&.collection-info {
		// height: 100%;
	}

	.info.collection {
		// height: 100%;
		display: flex;
		align-items: flex-end;
	}
}
</style>

<style lang="scss" scoped>
.btn-default-outline {
	background-color: white;
	border: 1px solid #c3161c;
	color: #c3161c;
	width: 100%;
	&:hover {
		background-color: darken(white, 5%);
		border: 1px solid darken(#c3161c, 10%);
		color: darken(#c3161c, 10%);
	}
}
</style>
